// COLORS
@primary: var(--primaryColor); // rgba(0, 102, 51, 1)
@primary-light: var(--primaryColorLight); // rgba(0, 102, 51, 0.75)
@primary-light-without-opacity: rgba(0, 210, 145, 1);
@primary-focus: var(--primaryColorFocus); // rgba(0, 210, 145, 1)
@primary-aux: var(--primaryColorBack); // rgba(151, 147, 155, 0.75)

@red: rgba(220, 53, 69, 0.75);
@red-hover: rgba(220, 53, 69, 1);

@disabled: #a3a1a1;

input, select, textarea, div.input-cont	{
  font-size: 15px;
  font-weight: 300;
}

:focus {
  outline: none;
  transition: box-shadow 0.3s ease;
}

button:focus {
  outline: 2px @primary-focus;
  box-shadow: none;
}

html {
  font-family: "Outfit", sans-serif;
}

body {
  font-family: "Outfit", sans-serif;
}

h1 {
  font-family: "Outfit", sans-serif;
  font-size: 32px !important;
  font-weight: bold;
  letter-spacing: 1.1px;
  margin: 0;
}

h2 {
  font-family: "Outfit", sans-serif;
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: 2.1px;
  margin: 0;
  color: @primary-light;
}

h3 {
  font-family: "Outfit", sans-serif;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin: 0;
}

h4 {
  font-family: "Outfit", sans-serif;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 2.1px;
  margin: 0;
  line-height: 1.8;
}

h5 {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 1.8px;
  margin: 0;
}

strong {
  font-family: "Outfit", sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0;
}

p {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 300;
  letter-spacing: 0.6px;
  margin: 0;
}

span {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin: 0;
}

a {
  color: #161c2d;

  &:focus,
  &:hover,
  &:active {
    color: @primary-focus;
  }
}

label {
  font-family: "Outfit", sans-serif;
  font-size: 11px !important;
  font-weight: 300 !important;
  letter-spacing: 0.6px;
  margin: 0;
  color: rgba(146, 146, 146, 1);
}

small {
  font-family: "Outfit", sans-serif;
  font-size: 10px !important;
  font-weight: 200;
  letter-spacing: 1.2px;
  margin: 0;
}

th {
  font-family: "Outfit", sans-serif;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px;
  margin: 0;
  color: white;
}

th span {
  font-family: "Outfit", sans-serif;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px;
  margin: 0;
  color: white;
}

td {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 300;
  letter-spacing: 0.4px;
  margin: 0;
  color: #161c2d;
}

.btn-default {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 12px;
  column-gap: 6px;
  border-radius: 6px;
  border: none;
  background-color: @primary-light;
  color: white;
  box-shadow: 00px 3px 7px 0px rgba(0, 0, 0, 0.1);

  &:disabled {
    color: #444;
    background-color: #f8f9fa;
    opacity: 0.6;

    &:hover {
      color: #444;
      background-color: #f8f9fa;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}


.only-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  padding: 0;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

.only-icon i {
  font-size: 20px;
  // transform: translateX(-0.5px) !important;
}

.btn-default:hover,
.btn-default.active,
a.btn-default:hover,
a.btn-default:focus,
a.btn-default.active {
  cursor: pointer;
  background-color: @primary;
  color: white;
}

.btn-aux {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 12px;
  column-gap: 6px;
  border-radius: 6px;
  border: none;
  // background-color: @primary-aux;
  background-color: rgba(151, 147, 155, 0.75);

  color: white;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);

  &:disabled {
    color: #444;
    background-color: #f8f9fa;
    opacity: 0.6;

    &:hover {
      color: #444;
      background-color: #f8f9fa;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.btn-aux:hover {
  cursor: pointer;
  background-color: rgba(151, 147, 155, 1);
  color: white;
}

.btn-aux-table {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 12px;
  column-gap: 6px;
  border-radius: 6px;
  border: none;
  // background-color: @primary-aux;
  background-color: rgba(151, 147, 155, 0.75);

  color: white;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

.btn-aux-table:hover {
  cursor: pointer;
  background-color: rgba(151, 147, 155, 1);
  color: white;
}

.btn-red {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 12px;
  column-gap: 6px;
  border-radius: 6px;
  border: none;
  background-color: @red;
  color: white;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

.btn-red:hover {
  cursor: pointer;
  background-color: @red-hover;
  color: white;
}

.btn-toggler:hover {
  cursor: pointer;
}

.input-label-cont {
  width: 100%;
  height: 60px;
  padding: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.input-label-cont.extra {
  flex-grow: 1;
  width: unset !important;
  max-width: 280px;
  min-width: 200px;
}

.input-label-cont.search {
  // width: 460px !important;
  max-width: 280px;
  min-width: 200px;
}

.form-fields-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;
}

.input-cont {
  width: 100%;
  padding: 4px 0 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  position: relative;
  box-shadow: inset 0 -1px 0 #cfcfcf;
  color: rgba(146, 146, 146, 1);
  min-height: 2em;
}

.input-cont.no-line {
  box-shadow: none;
  padding-top: 10px;
}

.input-cont:has(:focus) {
  box-shadow: inset 0 -1px 0 @primary-focus !important;
}

.input-cont:has(.ng-invalid.ng-dirty),
.input-cont:has(.ng-invalid.ng-touched) {
  box-shadow: inset 0 -1px 0 @red !important;
}

.input-txt {
  width: 100%;
  border: none;
  background-color: transparent;
}

.input-txt::placeholder {
  padding-left: 4px;
  font-family: "Outfit", sans-serif;
  font-size: 13px !important;
  font-weight: 100;
  letter-spacing: 0.3px;
  margin: 0;
}

input:focus-visible {
  outline: none;
}

.input-select {
  width: 100%;
  border: none;
  background-color: transparent;
  padding-bottom: 5px;
}

select:focus-visible {
  outline: none;
}

// BEGIN CHECKBOX
.verified-checkbox {
  display: none;
}

.custom-checkbox {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 33px;
  border: 2px solid @primary;
  cursor: pointer;
}

.custom-checkbox.move-file.checked {
  border-color: @primary !important;
  color: @primary !important;
}

.custom-checkbox.checked {
  border-color: @primary-focus !important;
  color: @primary-focus !important;
}

.custom-checkbox.gray {
  border-color: rgb(165, 165, 165) !important;
  color: rgb(165, 165, 165) !important;
}

.custom-checkbox.disabled {
  border-color: grey;
  cursor: not-allowed;
}

.custom-checkbox::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.2s;
}

.custom-checkbox.checked::before {
  opacity: 1;
}

.custom-checkbox.gray::before {
  opacity: 1;
  border-color: rgb(165, 165, 165) !important;
  color: rgb(165, 165, 165) !important;
}

.custom-checkbox.disabled {
  border-color: grey;
  cursor: not-allowed;
}

// END CHECKBOX

.icon-btn {
  color: rgba(146, 146, 146, 1);
  font-size: 14px;
}

.icon-btn:hover {
  cursor: pointer;
}

.router-oulet-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.table-card {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 10px;
}

.table-card-header {
  width: 100%;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-card-sub-header {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 10px;
}

.sub-header-form-cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.sub-header-btn-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-card-body {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.table-card-body table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-card-body.fixed-height {
  height: calc(100vh - 450px) !important;
  overflow-y: auto;
}

.table-card-body.fixed-height-small {
  height: calc(100vh - 645px) !important;
  overflow-y: auto;
}


.table-card-body.fixed-height-lib {
  height: calc(90vh - 450px) !important;
  overflow-y: auto;
}

// Maximizar modales

.modal-dialog.fullscreen .table-card-body.fixed-height-lib {
  height: calc(100vh - 380px) !important;
  overflow-y: auto;
}

// 

.table-card-body.fixed-height-persons {
  height: calc(90vh - 450px) !important;
  overflow-y: auto;
}

.table-header {
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--semi-dark-grey, #97939b);
  color: white;
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

.table-header th,
.table-body td {
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-header app-orderable-table-column + th {
  overflow: unset;
}

th.column-fb,
td.column-fb,
th.column-pep,
td.column-pep {
  width: 25%;
}

.title-header-cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
}

.header-title {
  font-size: 13px;
}

table th {
  font-size: 13px;
}

i.title-icon {
  margin-top: 0px;
  position: absolute;
  margin-left: 10px;
}

.table-body {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  color: var(--txt-main-color, #161c2d);
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

.table-body.selectable:hover {
  cursor: pointer;
  background-color: #e4e4e475;
  border-bottom: 1px solid var(--primaryColorFocus);
}

.table-body.suggested.selectable:hover {
  cursor: pointer;
  // background-color: #dbd054;
  background-color: #dbd054;
  border-bottom: 1px solid var(--primaryColorFocus);
}

.table-body-library {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  color: var(--txt-main-color, #161c2d);
  padding: 6px 10px 6px 10px;
  border-bottom: 1px solid lightgray;
}

.table-body-library.selectable:hover {
  cursor: pointer;
  background-color: #e4e4e475;
  border-bottom: 1px solid var(--primaryColorFocus);
}

.table-body-library.suggested.selectable:hover {
  cursor: pointer;
  // background-color: #dbd054;
  background-color: #dbd054;
  border-bottom: 1px solid var(--primaryColorFocus);
}

.table-body-library td {
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-row-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 6px;
}

.table-row-cont-library {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0px;
}

.table-card-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.actions-col {
  max-width: 200px;
  text-align: center;
}

.flag-col {
  max-width: 80px;
  text-align: center;
}

.end-date-col {
  max-width: 105px !important;
}	

.email-col {
  min-width: 225px !important;
}

.verified-col-th, .verified-col-td {
  text-align: center;
}

.verified-col-td {
  display: ruby;
}	

//==============================================================================================================================================================================

sidebar-menu .nav-item {
  max-width: 120px;
}

@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 3rem;
  margin-top: -4px;
  width: 3rem;
  object-fit: cover; /* Ensures the image doesn't distort */
}

.form-group.required .col-form-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

i.nav-icon.material-icons-outlined {
  font-size: 28px !important;
  margin-bottom: 0px !important;
}

@media (max-width: 1000px) {
  i.nav-icon.material-icons-outlined {
    font-size: 21px !important;
  }
}



/* Sweet alert */
// .swal2-title {
//   display: inline-block !important;
// }

// span.swal2-x-mark,
// span.swal2-x-mark-line-left,
// span.swal2-x-mark-line-right {
//   font-size: 16px !important;
// }

// div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
//   background-color: @primary;
// }

// div:where(.swal2-container) button:where(.swal2-styled) {
//   box-shadow: none;
// }

// body.swal2-height-auto {
//   height: inherit !important;
// }

// .swal2-popup.swal2-toast {
//   padding: 10px 12px !important;
//   display: block !important;
// }

// .swal2-popup.swal2-toast .swal2-header,
// .swal2-popup.swal2-toast .swal2-title,
// .swal2-popup.swal2-toast .swal2-content {
//   text-align: left !important;
//   margin: 0 !important;
// }

// .swal2-popup.swal2-toast .swal2-title {
//   margin-bottom: 4px !important;
// }

// .swal2-actions {
//   flex-direction: row-reverse;
// }

// .swal2-actions > button {
//   min-width: 100px;
// }

// div.wrapper,
// div.content-wrapper {
//   min-height: 100vh;
// }

// Modo prueba. ToDo: quitar los !important y limpiar comentario

.swal2-title {
  // display: inline-block !important;
  display: inline-block;
}

.swal2-popup {
  padding-top: 20px !important;
}

span.swal2-x-mark,
span.swal2-x-mark-line-left,
span.swal2-x-mark-line-right {
  // font-size: 16px !important;
  font-size: 10px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: @primary;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  box-shadow: none;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-actions {
  flex-direction: row-reverse;
}

.swal2-actions > button {
  min-width: 100px;
}

div.wrapper,
div.content-wrapper {
  min-height: 100vh;
}

//

.swal2-toast {
  padding: 20px 30px !important;
  background-color: var(--primaryColorLight) !important;
  border-radius: 12px !important;
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.2) !important;
  font-family: 'Outfit', sans-serif !important;
  font-size: 18px !important;
  max-width: 600px !important;
}

.swal2-toast .swal2-title {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
  color: #ffffff !important;
}

.swal2-toast .swal2-content {
  font-size: 18px !important;
  color: #ffffff !important;
}

.swal2-toast .swal2-icon {
  width: 50px !important;
  height: 50px !important;
  color: white !important;
  background-color: transparent !important;
}

.swal2-toast .swal2-success-icon .swal2-success-line-tip,
.swal2-toast .swal2-success-icon .swal2-success-line-long {
  background-color: white !important;
}

.swal2-toast.swal2-success {
  background-color: var(--primaryColorFocus) !important;
}

.swal2-toast.swal2-warning {
  background-color: #ffb822 !important;
}

.swal2-toast.swal2-error {
  background-color: #f4516c !important;
}

.swal2-container.swal2-toast-shown {
  top: 20px !important;
  right: 20px !important;
  transform: scale(1.1) !important;
}




//////////////////////
//     CUSTOM     ////
//////////////////////

// CONTAINERS
.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
}

.flex-container-small {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  .tabset-cont {
    width: 100%;
    height: 100%;
  }

  .tab-cont {
    padding: 10px;
    height: 100%;
  }
}

.flex-container-100 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  .tabset-cont {
    width: 100%;
    height: 100%;
  }

  .tab-cont {
    padding: 10px;
    height: 100%;
  }
}

.filter-range-option-close{
  color: @primary-light !important;
  line-height: 0px;
}

.dropdown-menu {
  width: 100%;
  overflow: hidden;
}

.dropdown-menu .dropdown-item {
  background-color: transparent;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu .dropdown-item:hover {
  color: white;
  background-color: @primary-focus !important;
}

.dropdown-menu .active {
  color: white;
  background-color: @primary-focus !important;
}

///////// Tooltips /////////

// GENERAL
// * { border-radius: 0 !important; }
// * { -webkit-border-radius: 0 !important; -moz-border-radius: 0 !important; border-radius: 0 !important; }
// @border-radius-base: 0px;
// @border-radius-large: 0px;
// @border-radius-small: 0px;
.tooltip-inline {
  white-space: nowrap;
  max-width: none;
}

.tooltip-inner {
  background-color: var(--semi-dark-grey, #97939b);
  border: 1px solid #c2c0c4;
  font-family: "Outfit", sans-serif;
}

// DDL Tooltips

.select-tooltip-container {
  position: relative;
  display: inline-block;
  // width: 100%;
}

.tooltip-inline {
  // white-space: nowrap;
  // max-width: none;
  width: max-content;
  max-width: 600px;
  text-align: left;
  white-space: normal;
  position: absolute;
  top: 100%;
  left: 0%;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
  pointer-events: none;
}

.tooltip-inner {
  background-color: var(--semi-dark-grey, #97939b);
  border: 1px solid #c2c0c4;
  font-family: "Outfit", sans-serif;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  word-wrap: break-word;
}

.select-tooltip-container:hover .tooltip-inline {
  visibility: visible;
  opacity: 1;
}

//

.no-wrap {
  white-space: nowrap;
}

.cursor-default {
  cursor: default !important;
}

.uppercase {
  text-transform: uppercase;
}

.border-r-0 {
  border-radius: 0px !important;
}

textarea {
  outline: none !important;
}

textarea:disabled {
 color: #929292;
}

html {
  scroll-behavior: smooth;
}

.opacity-half {
  opacity: 0.5;
}

.pointer {
  cursor: pointer !important;
}

.c-initial {
  cursor: initial;
}

.br-10 {
  border-radius: 10px;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.overlay-container {
  display: block;
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  opacity: 0.8;
}

.overlay-content app-overlay-contents {
  display: none;
}

.overlay-content:hover {
  opacity: 1;
}

.overlay-content:hover app-overlay-contents {
  display: initial;
}

.overlay-content-message {
  top: 35%;
  position: relative;
  text-align: center;
}

.underline {
  text-decoration: underline;
}

body {
  overflow: hidden;
  background-color: #eee;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  height: 100vh !important;
}

.p-absolute {
  position: absolute !important;
}

mark {
  background-color: #ffc107;
  color: black;
  padding: 0;
}

// Checkbox
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: @primary;
  background-color: @primary;
}

.custom-checkbox2
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: @primary-light;
}

// LAYOUT
.content-wrapper {
  background-color: #eee;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - calc(3.5rem + 10px) - calc(3.5rem + 10px));
}

.content-column-1 {
  flex-grow: 93;
}

.content-column-2 {
  flex-grow: 5;
  height: calc(100vh - 135px);
  padding: 10px;
}

.content-column-2 .right-menu {
  background-color: @primary-aux;
  display: block;
  height: 100%;
  border-radius: 6px;
  text-align: center;
  max-width: 96px;
  margin: auto;

  .nav-item {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 5px;
  }

  i {
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    padding: 7px 8px;
    border-radius: 17px;

    &:hover {
      background-color: @primary;
    }
  }
}

nav.main-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.brand-link .brand-image {
  margin-top: 0px;
  margin-left: 6px;
  max-height: 28px;
}

.sidebar-open .brand-link,
.sidebar-collapse .brand-link {
  padding: 20px;
}

.sidebar-open .brand-link img {
  opacity: 1 !important;
}

.sidebar-mini.sidebar-collapse .sidebar-bottom .nav-sidebar .nav-link p {
  display: none;

  &:hover {
    display: inline-block;
  }
}

.header-user-name {
  color: var(--txt-main-color, #161c2d);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 112.5% */
  letter-spacing: 1.5px;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: @primary-focus;
}

.sidebar-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.sidebar-bottom .nav-link {
  &:focus,
  &:hover {
    background-color: #ffffff1a;
  }
}

.sidebar-bottom a {
  color: #c2c7d0 !important;
  margin-bottom: 0px !important;

  &:focus,
  &:hover {
    color: #fff !important;
  }
}

.sidebar-bottom a.active {
  color: #fff !important;
}

// MODALS
.draggable-header:hover {
  background-color: #eee;
}

.control-button {
  float: right;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: transparent;
  border: none;
  margin-left: 5px;

  &:hover {
    opacity: 1;
  }
}

.modal-dialog {
  padding-top: 8vh;
}

.modal-dialog.fullscreen {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal-dialog.fullscreen .modal-content {
  min-height: 100vh;
  height: 100%;
  border-radius: 0;
  left: 0px !important;
  top: 0px !important;
}
.modal-dialog.fullscreen ng-component,
.modal-dialog.fullscreen form,
.modal-dialog.fullscreen .modal-body,
.modal-dialog.fullscreen app-library-action-folder-create,
.modal-dialog.fullscreen app-library-action-file-create,
.flex {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}

label.required::after {
  content: " *";
  color: red;
  margin-left: 5px;
}

.modal.minimized {
  height: 60px !important;
  // height: 50px !important;
  // top: calc(100% - 50px);
  top: calc(100% - 55px);
  margin-bottom: 5px;
  position: fixed;
  left: auto;
  width: 226px;
  right: 0;
  z-index: 1;
}

.modal.minimized .modal-content {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 16px !important;
}

.modal.minimized:hover .modal-content {
  background-color: #eee;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal + .modal.minimized {
  margin-right: 226px;
}

.modal + .modal + .modal.minimized {
  margin-right: 452px;
}

.modal + .modal + .modal + .modal.minimized {
  margin-right: 678px;
}

.modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized,
.modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized,
.modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized,
.modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized,
.modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized + .modal.minimized {
  display: none !important;
}

.modal-dialog.minimized {
  bottom: 0px;
  position: absolute;
  margin-bottom: 0px;
  right: 0px;
  margin-right: 10px;
  width: 200px;
  margin-bottom: 10px;
}

.modal-dialog.minimized h4 {
	width: 150px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.modal-dialog.minimized .modal-content {
  left: 0px !important;
  top: 0px !important;
}

.modal-dialog.minimized .modal-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  border-radius: 16px !important;

  #maximize {
    display: none;
  }
  #minimize {
    display: none;
  }
}

.modal-dialog.minimized .modal-body,
.modal-dialog.minimized abp-modal-footer,
.modal-dialog.minimized .modal-footer {
  display: none;
}

.buttons-container {
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  // padding: 0 10px 0 10px;
  padding: 0 10px 0 0;
}

.buttons-container.right {
  justify-content: flex-end;
}

.buttons-container.center {
  justify-content: center;
}

.buttons-container.left {
  justify-content: left;
}

.btn-row {
  background: none;
  border: none;
  font-size: 14px;

  &:hover {
    color: var(--primaryColorFocus);
  }

  &:disabled {
    color: #CCC !important;
  }

  &.red {
    color: @red;

    &:hover {
      color: #dc3545;
    }
  }

  &.green {
    color: var(--primaryColorLight);

    &:hover {
      color: var(--primaryColor);
    }
  }

  i {
    font-size: 20px !important;
  }
}

.btn-tab-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.btn-tab {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin: 0;
  background: none;
  color: #495057;
  padding: 14px 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: -1px;
  border: none;

  &.active {
    border: 1px solid #dee2e6;
    border-bottom-color: #fff;
  }
}

// LOGIN
body.login-page,
body.register-page {
  background-color: #0e713f;
  align-items: unset;
}

account-footer {
  color: #fff;
}

div.login-logo a {
  color: #fff;
}

div.login-logo img {
  max-width: 100%;
}

.login-box,
.register-box {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
  width: 505px;
  flex-shrink: 0;
  padding: 30px 60px 30px 60px;
}

div.login-logo {
  background-image: url(../assets/img/logo.svg);
  width: auto;
  min-height: 45px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 30px;
}

a.forgot-password-link,
a.go-back-link {
  color: var(--txt-main-color, #161c2d);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  text-decoration-line: underline;
}

.login-box span.error {
  color: #f22727;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 180% */
  letter-spacing: 1px;
}

.powered {
  color: var(--main-bkg, #eee);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  letter-spacing: 1px;
  display: inline-block;
  margin-right: 10px;
}

account-footer {
  position: fixed;
  bottom: 65px;
  width: 530px;
  text-align: center;
}

// INPUTS
input.form-control {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18) inset;
}

input.form-control:active,
input.form-control:focus {
  border: 0.7px solid var(--cta-green, @primary);
}

.input-group-prepend .btn {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group-append .btn {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

input:disabled {
  color: @disabled;
}

// TABLES
app-orderable-table-column {
  display: none;
}

div.login-logo img {
  max-width: 100%;
}

.filter-active {
  color: var(--primaryColorFocus);
}

// SPINNER
// Disable ABP Freeze UI spinner
.freeze-ui {
  display: none !important;
}

// WIZARD STEPS
.steps {
  list-style: none;
  display: flex;
  justify-content: left;
  margin-bottom: 60px;
  padding: 0;

  li {
    text-align: center;
    padding-right: 10%;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 28px,
      @primary-focus 28px,
      @primary-focus 30px,
      @primary-focus 32px,
      transparent 32px,
      transparent 100%
    );

    &:last-child {
      padding-right: 0px;
      width: 0px;

      span.text {
        margin-left: 0px !important;
      }
    }
  }

  a {
    &:hover,
    &:active {
      text-decoration: none;
    }

    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #929292;

    &.current {
      color: #161c2d;
      font-weight: 900;

      .number {
        border: 4px solid @primary-focus;
        color: #fff;
        font-weight: 400;
        background-color: @primary-focus;
      }
    }
  }

  .text {
    position: absolute;
    font-size: 12px;
    margin-left: -20px;
    max-width: 100px;
    text-align: left;
  }

  .number {
    background: #fff;
    margin-bottom: 5px;
    border: 3px solid @primary-focus;
    color: @primary-focus;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    display: block;
    font-size: 13px;
    margin-top: 12px;

    &:hover {
      background-color: @primary-focus;
      color: #fff;
    }

    &.disabled {
      background-color: #eee;
      color: #ccc;
      border: 3px solid #ccc;
    }
  }
}

.sm-overflow {
  height: 40vh;
  overflow-y: auto;

  &.settings {
    height: 53vh;
    overflow-y: auto;
  }
}

.btn-notifications {
  background-color: @primary-aux;
  color: #ffffff;
}

.badge-notification {
  color: white;
  background-color: @primary;
  font-size: 100%;
}

.badge-notification-button {
  color: white;
  background-color: @primary-aux;
  font-size: 100%;
}

.popover-body {
  padding: 0 !important;
  color: #212529 !important;
}

.popover {
  max-width: 100% !important;
  background-color: #dcdada !important;
  border: none !important;
}

.popover-body {
  padding: 0 !important;
}

.popover .arrow {
  display: none !important;
}

///////// Full calendar /////////
.fc-col-header-cell-cushion {
  color: black !important;
}

.fc-daygrid-day-number {
  color: black !important;
  margin: auto;
  cursor: pointer;
}

.fc-theme-standard {
  height: 310px;
  width: 270px;
}

.fc .fc-toolbar-title {
  font-size: 18px !important;
  margin: 0;
  color: black !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
}

.fc .fc-button .fc-icon {
  font-size: 1.5em !important;
  vertical-align: middle;
}

.fc .fc-button-primary {
  color: black;
  background-color: transparent;
  border: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: white;
  background-color: transparent;
  border: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

.fc .fc-button-primary:hover {
  color: white;
  background-color: transparent;
  border: none;
}

.fc .fc-button-primary:focus {
  box-shadow: none;
}

.fc .fc-button:focus {
  box-shadow: none;
  outline: 0px;
}

.fc-theme-standard td {
  border: 1px solid #eee !important;
}

.fc .fc-scrollgrid {
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-collapse: collapse;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0 !important;
}

.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
  top: -20px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--primaryColorFocus);
  color: #fff;

  a {
    color: #fff !important;
  }
}

.fc-scrollgrid-sync-table a {
  font-size: 10px;
  margin-top: 5px;
}

.fc-toolbar-chunk {
  font-size: 11px;
}

///////// Generic Containers /////////
.generic-card {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 10px;
}

.generic-card form { width: 100%; }

.generic-card .body {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.generic-card .body.fixed-height {
  height: calc(100vh - 450px) !important;
  overflow-y: auto;
}


///////// Full calendar /////////

/////////// LIBRARY ///////////

.p-tree {
  border:none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: @primary-focus;
}

.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
  box-shadow: inset 0 0 0 0.15rem @primary-focus;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color:@primary-focus;
  border-color: transparent;
  background: transparent;
}

.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content .p-tree-toggler:enabled:hover{
  color:#fff;
  border-color: transparent;
  background: transparent;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:enabled:hover{
  color:#fff;
  border-color: transparent;
  background: transparent;
}

.p-treenode-selectable:hover{
  background-color: #e4e4e475 !important;
}

.p-selectbutton .p-button.p-highlight {
  background: @primary-focus;
  border-color: @primary-focus;
  border-right-color: @primary-focus;
}

.p-selectbutton .p-button.p-highlight:focus > .p-treenode-content {
  box-shadow: inset 0 0 0 0.15rem @primary-focus;
}

.tree-node-label {
  padding: 6px;
}

.library-icon {
  font-size: 0.9rem;
}

.lib-item {
    font-size: 0.8rem;
    margin-right: 5px;
}

.library-field-stnd {
  border-color: @primary-light;
  color: @primary-focus;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .library-field {
  border-color: @primary-light;
  color: @primary-focus;
  font-size: 0.8rem;
  margin-right: 5px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .library-field {
  color: #ffffff!important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .library-field-dark {
  color: #ffffff!important;;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .library-field-dark {
  border-color: @primary;
  color: @primary;
  font-size: 0.8rem;
  margin-right: 5px;
}

.library-field-dark-stnd {
  border-color: @primary;
  color: @primary;
}

.p-tree-empty-message {
  font-family: "Outfit", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.8px;
}

////////// Library input search //////////
.p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
}

.p-tree-filter-container {
    position: relative;
    display: none;
    max-width: 280px;
    min-width: 200px;
}

.p-tree .p-tree-filter-container .p-tree-filter {
    // width: 100%;
    max-width: 280px;
    min-width: 200px;
    padding-right: 1.429rem;
}

.p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #000000;
    background: #ffffff;
    padding: 0.429rem 0.429rem;
    border: 0px;
    border-bottom: 1px solid #cfcfcf;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    box-shadow: inset 0 -1px 0 #cfcfcf;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    padding-left: 30px;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 -1px 0 @primary-focus !important;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    left: 0.429rem;
    color: #929292;
}

::-webkit-input-placeholder {
  color: #a1a0a0;
}

///////// End library input search ////////////

//////// Prime Context menu theme /////////

.p-contextmenu {
    padding: 0;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    width: 12.5rem;
    z-index: 99999 !important;
  }

  .p-contextmenu ul {
    margin: 0 !important;
    padding: 0;
    list-style: none;
  }

  .p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
  }

  .p-contextmenu .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: @primary-focus;
    font-weight: bold;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: @primary-focus;
    font-weight: bold;
  }

  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }

  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .custom-checkbox{
    background: @primary-focus;
    border-color: #ffffff!important;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .custom-checkbox::before{
    border-color: #ffffff!important;
    color: #ffffff!important;
  }

//////// End Prime Conext menu theme //////

.wider {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

///////// Scroll bar /////////

/* WebKit (Chrome, Safari) */

/* width */
::-webkit-scrollbar {
  width: 11px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(156, 155, 155);
  border-radius: 6px;
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3f3f3;
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(154, 153, 153, 0.9);
}

/* Firefox */

/* Track and Handle */
*::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(156, 155, 155);
  border-radius: 6px;
  background: #d9d9d9;
}

*::-moz-scrollbar-thumb {
  background: #f3f3f3;
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(154, 153, 153, 0.9);
}

//

.input-label-cont > .input-cont p.input-txt{
  font-size: 1rem !important;
}

.bg-lightgray-custom {
    background-color: rgb(231, 231, 231);
}

.show-end-date {
  position: absolute;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
}

//

.form-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .form-grid-item {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    .form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
  }

  //

.disclaimer-text {
  font-size: 0.875rem;
  color: #555;
  background-color: #f8f9fa;
  border-left: 4px solid rgba(0, 210, 145, 1);
  border-left: 4px solid var(--primaryColorFocus);
  padding: 10px 15px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button.current {
    border-radius: 15px;
    max-width: 155px;
    padding: 0 13px;
    text-transform: capitalize;
}

.bs-datepicker-container {
  text-transform: capitalize;
}

#calendar-container {
  text-transform: capitalize;
}.extra-info {
  margin-left: 2px;
  font-size: 1rem;
  color: #495057;
  cursor: pointer;
}

/* Mobile - Ajustes responsives */
@media (max-width: 1000px) {
  .copyright-span {
    display: none;
  }
}

@media (max-width: 800px) {
  .client-logo-span img {
    max-width: 90px;
  }

  .client-logo-span span {
    font-size: 10px !important;
  }
}

//

.empty-field {
  border-bottom: 1px solid #858383;
}

.pi-sitemap {
  color: var(--primaryColor) !important;
}

.table-card > .search {
  height: 20px;
}

.p-tree-container {
  color: #7b7878;
}

//

.p-menuitem-content .p-menuitem-text {
  padding: 5px !important;
  color: #7b7878 !important;
}

.user-preferences-tooltip {
  left: 30px;
}

// ABP-Confirm Modales de confirmación

.abp-confirm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: @primary-light;
  color: white;
  border-bottom: 1px solid @primary;
}

.modal-header-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-header-close {
  font-size: 16px;
  cursor: pointer;
  color: white;
  background: none;
  border: none;
}

.modal-header-close:hover {
  color: @primary-focus;
}

.swal2-icon.swal2-warning {
  margin-top: 20px;
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f8bb86;
  border-radius: 50%;
}

.swal2-icon-content {
  font-size: 40px;
  font-weight: bold;
  // color: white;
  line-height: 80px;
}

.swal2-title {
  font-size: 18px;
}

//

.swal2-html-container, .swal2-icon-content{
  font-size: 14px !important;
}

.swal2-icon {
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px !important;
}

.swal2-icon-content {
  font-size: 30px !important;
}

// Efecto Round en los botones generales

// Ajuste general a botones
button,
.btn-default,
.btn-aux,
.btn-red {
  border-radius: 100px !important;
}

.only-icon {
  border-radius: 6px !important;
}

/* Excluye los elementos de los dropdown (select) del efecto border-radius */
select,
option,
.dropdown-item {
  border-radius: 0 !important;
}

.suggested {
    background-color: rgba(232, 226, 35, 0.65); /* 70% opacity */
}

.table-card-body table thead {
  position: sticky;
  top: 0;
}

// Checkbox - Role edit/create
.inline-flex {
  display: inline-flex;
}

.role-small-font {
  font-size: 10px !important;
}

.today-class {
  background-color: lightgray;
}

.user-avatar-img {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ensures the image doesn't distort */
}

.theme-custom .bs-datepicker-head {
    background-color: @primary-light;
}

.theme-custom .bs-datepicker-body table td.week span {
    display: none;
}

.theme-custom .bs-datepicker-body table td span.selected, .theme-red .bs-datepicker-body table td.selected span, .theme-red .bs-datepicker-body table td span[class*=select-]:after, .theme-red .bs-datepicker-body table td[class*=select-] span:after {
    background-color: @primary-light;
}

.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
    color: black !important;
}

.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.disabled span {
    color: rgba(0, 0, 0, 0.25) !important;
}

///////// Fullscreen de Resumen societario /////////

/* Ocultar sidebar, header, footer, y los iconos cuando fullscreen esté activo */
.fullscreen-active header,
.fullscreen-active footer,
.fullscreen-active sidebar,
.fullscreen-active .sidebar,
.fullscreen-active .breadcrumb-cont,
.fullscreen-active .noti-menu,
.fullscreen-active .header,
.fullscreen-active .nav-tabs,
.fullscreen-active .footer-cont,
.fullscreen-active .bottom-icons { 
  display: none !important; 
}

.fullscreen-active .main-page-cont { 
  padding-top: 0px !important; 
  padding-left: 0px !important; 
}

.fullscreen-active .cont { 
  height: 100% !important;
  padding: 5px 5px 5px 5px;
}

.fullscreen-active .tab-content { 
  height: 100% !important; 
}

.fullscreen-active .tab-pane { 
  height: 100% !important; 
  overflow: hidden !important; 
}

.fullscreen-active .map-container { 
  height: inherit !important; 
}

/* Asegura que el contenido principal ocupe toda la pantalla */
.fullscreen-active .main-content {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important; 
}

// 

.fa-eye,
.fa-eye-slash {
  cursor: pointer;
}

.show-all-button {
    color: white;
    text-align: center;
    padding: 1rem;
    background-color: @primary-light;
}

.multiselect-item {
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 0;
  width: 100%;
}

.p-multiselect .p-multiselect-label {
  padding-block: 1px;
  padding-inline: 2px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: @primary;
  background: @primary;
}

// 

.checkbox-same {
  margin: 3px;
}

// 

/* Para que el contenedor y el ícono del calendario muestren cursor 'pointer' si el input está habilitado */
.input-cont.icon-addon > input:not([disabled]) {
  cursor: pointer;
}

.input-cont.icon-addon > input:not([disabled]) ~ .calendar-icon {
  cursor: pointer;
}

/* Para que el contenedor y el ícono del calendario muestren cursor 'default' si el input está deshabilitado */
.input-cont.icon-addon > input[disabled] {
  cursor: default;
}

.input-cont.icon-addon > input[disabled] ~ .calendar-icon {
  cursor: default;
}

// Estilos generales para los checkboxes no redondos

/* Ocultar el checkbox original */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #656b71;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: #f0f0f0;
  position: relative;
}

/* Estilo cuando el checkbox está marcado */
input[type="checkbox"]:checked {
  background-color: #656b71;
  border: 1px solid #ccc;
}

/* Añadir un tick cuando está marcado */
input[type="checkbox"]:checked::before {
  content: '\2713'; /* Unicode del tick */
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Estilo al hacer hover en el checkbox */
input[type="checkbox"]:hover {
  border-color: #999;
}

// 

.bordered-img {
  border: 4px solid @primary-light;
  border-radius: 50%;
}

// 

.center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.social-amount-col + th > .title-header-cont {
  justify-content: center !important;
}

// 

.shareholding-th-col + th > .title-header-cont > span {
  width: min-content;
}

/* 
.shareholding-th-col + i {
  // max-width: 150px;
}

.shareholding-col {
  // justify-content: center;
  // align-items: center;
  // text-align: center;
}

.shareholding-col, .shareholding-th-col + th {
  // max-width: 120px;
}
*/

.pep-col, .pep-th-col + th {
  max-width: 100px;
}

// 

.readonly-style {
  color: #888;
  cursor: default;
}

.selectable-file-explorer-folder {
  transition: 0.3s;
}

.selectable-file-explorer-folder:hover {
  background-color: #e4e4e475;
}

.context-menu-selected {
  background-color: var(--primaryColorFocus) !important;
}

.context-menu-selected td {
  color: white !important;
}

.context-menu-selected td i {
  color: white !important;
}

.p-image-preview-indicator {
  border-radius: 0 !important;
}

.truncate-modal-text {
  display: inline-block;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.pan-element {
  will-change: auto !important;
}

.nav-link.disabled {
  color: lightgray !important;
}

.nav-item.disabled {
  cursor: not-allowed !important;
}

.move-action-container {
  display: flex;
  align-items: center;
  text-wrap-mode: nowrap;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.20);
  border-radius: 10px;
  background: white;
  position: absolute;
  bottom: 15px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.fa-custom-icon {
  font-size: 21px;
  color: var(--primaryColor);
}

.tr-slide {
  animation: slideInFromTop 0.2s ease-out;
}

.warning-text {
  padding: 15px;
  color: white;
  background-color: orangered;
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-60%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}